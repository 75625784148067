import cookies from "react-cookies";

//save PL cookies
if (!cookies.load("language")) {
    cookies.save("language", "pl", { path: "/" });
}

const lg = cookies.load("language");

export const Language = {
    dogs: lg === "pl" ? "Nasze Psy" : "Our Dogs",
    litters: lg === "pl" ? "Mioty" : "Litters",
    aboutBreed: lg === "pl" ? "O rasie" : "About Breed",
    aboutUs: lg === "pl" ? "O nas" : "About Us",
    news: lg === "pl" ? "Aktualności" : "News",
    contact: lg === "pl" ? "Kontakt" : "Contact",
    growth: lg === "pl" ? "Wzrost" : "Growth",
    weight: lg === "pl" ? "Waga" : "Weight",
    tail: lg === "pl" ? "Ogon" : "Tail",
    imported: lg === "pl" ? "Importowany" : "Imported",
    pedigreeLink: lg === "pl" ? "Rodowód" : "Pedigree",
    created: lg === "pl" ? "Utworzony" : "Created",
    description: lg === "pl" ? "Opis" : "Description",
    didyouknow: lg === "pl" ? "Czy wiesz, że" : "Did you know?",
    owner: lg === "pl" ? "Właściciel" : "Owner",
    breeder: lg === "pl" ? "Hodowca" : "Breeder",
    jay:
        lg === "pl"
            ? "Jay Sisler i jego niezwykłe owczarki australijskie."
            : "Jay Sisler and his awesome Australian Shepherd dogs.",
    outLitters: lg === "pl" ? "Nasze mioty" : "Our Litters",
    ourDogs: lg === "pl" ? "Nasze psy" : "Our Dogs",
    females: lg === "pl" ? "Suki" : "Females",
    males: lg === "pl" ? "Psy" : "Males",
    birthDate: lg === "pl" ? "Data urodzenia" : "Date of birth",
    litter: lg === "pl" ? "Miot" : "Litter",
    gallery: lg === "pl" ? "Galeria" : "Gallery",
    welcome_title: lg === "pl" ? "Witamy!" : "Welcome!",
    welcome_subtitle:
        lg === "pl"
            ? "A Trustfall - hodowla z pasją"
            : "A Trustfall - kennel with passion",
    contact_withus: lg === "pl" ? "Skontaktuj się z nami!" : "Contact us!",
    send: lg === "pl" ? "Wyślij" : "Send",
    contact_form_name: lg === "pl" ? "Imię" : "Firstname",
    contact_form_email: lg === "pl" ? "E-mail" : "E-mail",
    contact_form_subject: lg === "pl" ? "Temat" : "Subject",
    contact_form_message: lg === "pl" ? "Wiadomość" : "Message",
    shepherd:
        lg === "pl"
            ? "Owczarek australijski (typ amerykański)"
            : "Australian Shepherd",
    footer:
        lg === "pl"
            ? "©2023 Hodowla owczarków australijskich A Trustfall (FCI)"
            : "©2023 Australian Shepherd Kennel A Trustfall (FCI)",

    footer_copy:
        lg === "pl" ? "Wszelkie prawa zastrzeżone" : "All rights reserved",
    puppies: lg === "pl" ? "Szczenięta" : "Puppies",
};
